import {useBetween} from "use-between";
import {useEffect} from "react";
import {SearchAdminsResetUseCase} from "./SearchAdminsResetUseCase";

function AutoSearchAdmins() {
    const searchAdminsResetUseCase = SearchAdminsResetUseCase();

    useEffect(() => {
        searchAdminsResetUseCase.execute();
    }, []);
}

export const AutoSearchAdminsUseCase = () => useBetween(AutoSearchAdmins);