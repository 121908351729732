import {useBetween} from "use-between";
import {useEffect} from "react";
import {SearchOwnersResetUseCase} from "./SearchOwnersResetUseCase";

function AutoSearchOwners() {
    const searchOwnersResetUseCase = SearchOwnersResetUseCase();

    useEffect(() => {
        searchOwnersResetUseCase.execute();
    }, []);
}

export const AutoSearchOwnersUseCase = () => useBetween(AutoSearchOwners);