import {ReactElement} from "react";
import {FRONT__COMMON_ADMIN_URL} from "../../../../../Constants/FrontUrls";
import Link from "../../../../Common/Link/Link";

interface ToAdminInterface {
    adminId: string;
    children: ReactElement|ReactElement[]|string|number;
}

function ToAdmin({adminId, children}: ToAdminInterface) {

    return <Link to={FRONT__COMMON_ADMIN_URL(adminId)}>
        {children}
    </Link>;
}

export default ToAdmin;