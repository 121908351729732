import Input from "../../../../../Common/Form/Input";
import Translator from "../../../../../../Utils/Translator";
import {LABEL_LOGIN} from "../../../../../../Constants/Labels";

interface InputLoginInterface {
    form: any;
    handleChange: any;
    handleBlur: any;
    handleFocus: any;
    className?: string;
}

function InputLogin({form, handleChange, handleBlur, handleFocus, className}: InputLoginInterface) {
    return <Input inputData={form.fields.login}
        name={'login'}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        displayBorders={true}
        placeholder={Translator.translate(LABEL_LOGIN)}
        size={"full"}
        className={className ?? ''}
    />;
}

export default InputLogin;