import {useEffect, useState} from "react";
import AbstractIndexedLoader, {
    IndexedLoaderObjectInterface
} from "./AbstractIndexedLoader";
import {useBetween} from "use-between";
import AdminApi from "../../Calls/AdminApi";

const cache = new AbstractIndexedLoader();

function Admins() {
    const [admins, setAdmins] = useState<IndexedLoaderObjectInterface>({});
    const [isLoading, setIsLoading] = useState<boolean|undefined>(false);

    const apiCall = async (adminId: string) => {
        return AdminApi.fetchAdmin(adminId);
    };

    const fetch = (adminIds: string[], forceRefresh: boolean= false) => {
        cache.load(adminIds, apiCall, forceRefresh);
        if (!isLoading) {
            setIsLoading(() => undefined);
        }
    };

    useEffect(() => {
        if (isLoading === undefined) {
            setIsLoading(() => true);
            if (cache.hasLoading()) {
                setTimeout(() => {
                    setIsLoading(() => undefined);
                }, 10);
            } else {
                setAdmins(() => ({...cache.get()}));
                setIsLoading(false);
            }
        }
    }, [isLoading]);

    const getAdminById = (adminId: string) => {
        return admins[adminId]?.data;
    }

    return {fetch, admins, getAdminById};
}

export const AdminsLoader = () => useBetween(Admins);