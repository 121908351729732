import {useBetween} from "use-between";
import {SearchAdminsUseCase} from "./SearchAdminsUseCase";

function SearchAdminsReset() {
    const {setKeywordDebounce} = SearchAdminsUseCase();

    const execute = () => {
        setKeywordDebounce(() => '');
    }

    return {execute};
}

export const SearchAdminsResetUseCase = () => useBetween(SearchAdminsReset);