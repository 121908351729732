import {useBetween} from "use-between";
import {FormHook} from "../../Hooks/Form/FormHook";
import {useEffect, useState} from "react";
import {AdminsLoader} from "../../Api/Loaders/IndexedLoaders/AdminsLoader";
import AdminApi from "../../Api/Calls/AdminApi";
import AbstractApi from "../../Api/Calls/AbstractApi";

function UpdateAdmin() {
    const [adminId, setAdminId] = useState<string|undefined>();
    const {resetSuccessError, form, handleChange, handleBlur, handleFocus, shouldSubmit, setForm, setValues, setConstrains} = FormHook(['login', 'firstname', 'lastname', 'email']);
    const {fetch: fetchAdmins, admins, getAdminById} = AdminsLoader();

    useEffect(() => {
        (async function() {
            const response = await AdminApi.fetchUpdateAdminFormValidator();
            if (AbstractApi.isSuccess(response)) {
                setConstrains(response.data);
            }
        })();
        resetSuccessError();
    }, []);

    useEffect(() => {
        if (undefined !== adminId) {
            fetchAdmins([adminId]);
        }
    }, [adminId]);

    useEffect(() => {
        if (adminId !== undefined) {
            const admin = getAdminById(adminId);
            if (admin !== undefined) {
                setValues({
                    ...admin
                });
            }
        }
    }, [admins]);

    const execute = async (e: any) => {
        e.preventDefault();
        if (!shouldSubmit() || adminId === undefined) {
            return;
        }

        setForm({
            ...form,
            isLoading: true,
            error: '',
            success: '',
        })

        const response = await AdminApi.updateAdmin(adminId, form);
        if (AbstractApi.isSuccess(response)) {
            setForm(() => ({
                ...form,
                isLoading: false,
                success: `update_admin_success`,
                error: ``
            }));

            fetchAdmins([adminId], true);
        } else {
            setForm(() => ({
                ...form,
                isLoading: false,
                success: ``,
                error: response.data
            }));
        }
    };

    return {setAdminId, form, handleChange, handleBlur, handleFocus, execute, setValues, setForm, setConstrains};
}

export const UpdateAdminUseCase = () => useBetween(UpdateAdmin);