import {useParams} from "react-router-dom";
import PageTemplate from "../../Layout/PageTemplate/PageTemplate";
import UpdateForm from "../../Libraries/Components/Domain/Location/Form/UpdateForm";

function UpdateLocation() {
    const {id} = useParams();

    if (id === undefined) {
        return null;
    }

    return <PageTemplate>
        <UpdateForm locationId={id}/>
    </PageTemplate>;
}

export default UpdateLocation;