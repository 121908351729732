import {Link} from "react-router-dom";
import {ReactComponent as CircledCross} from "../../Libraries/Icons/CircledCross.svg";
import PageTemplate from "../../Layout/PageTemplate/PageTemplate";
import Translator from "../../Libraries/Utils/Translator";
import Button from "../../Libraries/Components/Common/Button/Button";
import {SearchOwnersUseCase} from "../../Libraries/UseCases/Owner/SearchOwnersUseCase";
import {OwnersSearchesLoader} from "../../Libraries/Api/Loaders/IndexedLoaders/OwnersSearchesLoader";
import ComponentDevice from "../../Libraries/Components/Common/ComponentDevice/ComponentDevice";
import Card from "../../Libraries/Components/Domain/Owner/Card/Card";
import {AutoSearchOwnersUseCase} from "../../Libraries/UseCases/Owner/AutoSearchOwnersUseCase";
import Label from "../../Libraries/Components/Common/Label/Label";
import {
    CTA_CREATE_OWNER,
    LABEL_ADMIN_SEARCH_BY_KEYWORD,
    LABEL_FILTER_ZONE,
    LABEL_OWNER_SEARCH_BY_KEYWORD
} from "../../Libraries/Constants/Labels";
import {
    backgroundColor,
    border, borderColor,
    classes,
    flex,
    margin,
    padding,
    round,
    textColor,
    textSize, width
} from "../../Libraries/Theme/Theme";

function Owners() {
    AutoSearchOwnersUseCase();
    const {getOwnersSearchByKeyword} = OwnersSearchesLoader();
    const {keyword, keywordDebounce, setKeywordDebounce} = SearchOwnersUseCase();

    const ownersSearch = keyword === undefined ? [] : getOwnersSearchByKeyword(keyword) ?? [];

    return <PageTemplate>
        <div className={classes(width('100%'))}>
            <div className={classes(border(), borderColor('primary'), backgroundColor("secondary"), padding('xy', '10px'), round())}>
                <Label label={LABEL_FILTER_ZONE} className={classes(textSize('20px'))}/>
                <div className={classes(flex("center", 'between'))}>
                    <div>
                        <div className={classes(border(), borderColor("primary"), flex("center"), round(), backgroundColor("primary"), padding('xy', '5px'), margin('t', '10px'), border())}>
                            <div>
                                <input className={classes(padding('xy', '5px'), backgroundColor('primary'), textColor('primary'))} type={'text'} onChange={(e: any) => setKeywordDebounce(() => e.target.value)} value={keywordDebounce ?? ''} placeholder={Translator.translate(LABEL_ADMIN_SEARCH_BY_KEYWORD)}/>
                            </div>
                            <div>
                                <CircledCross className={`cursor-pointer`} width={`20px`} onClick={() => setKeywordDebounce('')}/>
                            </div>
                        </div>
                    </div>
                    <div>
                        <Link to={`/owners/create`}><Button className={classes(padding('xy', '10px', '', true))} label={CTA_CREATE_OWNER}/></Link>
                    </div>
                </div>
            </div>
            <div className={classes(flex("top", "center", '10px'), margin('t', '10px'))}>
                {ownersSearch.map((ownerId: string, index: number) => <Card key={`owner-${index}`} ownerId={ownerId}/>)}
            </div>
        </div>
    </PageTemplate>;
}

export default Owners;