import {ApiManager} from "../../Utils/ApiManager";
import {RESET_ALL_ACCESS_URL, RESET_ALL_URL} from "../../Constants/ApiUrls";
import AbstractApi from "./AbstractApi";

class SystemApi {
    static async resetAll(password: string) {
        try {
            const response = await ApiManager.post(RESET_ALL_URL, {password});
            return AbstractApi.returnSuccessResponse(response);
        } catch (e: any) {
            return AbstractApi.returnErrorResponse(e, 'resetAll', {password});
        }
    };

    static async resetAllAccess(password: string) {
        try {
            const response = await ApiManager.post(RESET_ALL_ACCESS_URL, {password});
            return AbstractApi.returnSuccessResponse(response);
        } catch (e: any) {
            return AbstractApi.returnErrorResponse(e, 'resetAllAccess', {password});
        }
    };
}

export default SystemApi;