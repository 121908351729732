import PageTemplate from "../../Layout/PageTemplate/PageTemplate";
import LoginForm from "../../Libraries/Components/Domain/Admin/Form/LoginForm";
import LabelNewLine from "../../Libraries/Components/Common/Label/LabelNewLine";
import {LABEL_LOG_IN} from "../../Libraries/Constants/Labels";

function Login() {
    return <PageTemplate centeredContent={true}>
        <LoginForm/>
    </PageTemplate>;
}

export default Login;