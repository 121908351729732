import './App.css';
import {LocaleContextHook, LocaleContextProvider} from "./Libraries/Contexts/LocaleContext";
import {TranslationContextHook, TranslationContextProvider} from "./Libraries/Contexts/TranslationContext";
import {
  FastManageLocationContextHook,
  FastManageLocationContextProvider
} from "./Libraries/Contexts/FastManageLocationContext";
import Router from "./Router";
import Translator from "./Libraries/Utils/Translator";
import {ThemeCache} from "./Libraries/Caches/System/ThemeCache";
import {syncTheme} from "./Libraries/Theme/Theme";
import {getLabel} from "./Libraries/Helpers/Label";
import {LABEL_SLOGAN} from "./Libraries/Constants/Labels";

function App() {
  const [locale, dispatchLocale] = LocaleContextHook();
  const [translation, dispatchTranslation] = TranslationContextHook();
  const [location, dispatchLocation] = FastManageLocationContextHook();
  const {theme} = ThemeCache();

  syncTheme(theme);
  Translator.loadTranslation(dispatchTranslation);
  document.title = `Queube - Back-office`;

  // @ts-ignore
  return <LocaleContextProvider value={{locale, dispatchLocale}}>
    {/*@ts-ignore*/}
    <TranslationContextProvider value={{translation, dispatchTranslation}}>
      {/*@ts-ignore*/}
      <FastManageLocationContextProvider value={{location, dispatchLocation}}>
        <Router/>
      </FastManageLocationContextProvider>
    </TranslationContextProvider>
  </LocaleContextProvider>;
}

export default App;
