import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import Login from "./Pages/Login/Login";
import Homepage from "./Pages/Homepage/Homepage";
import Translations from "./Pages/Translations/Translations";
import Locations from "./Pages/Locations/Locations";
import ConfigurePassword from "./Pages/ConfigurePassword/ConfigurePassword";
import CreateLocation from "./Pages/CreateLocation/CreateLocation";
import UpdateLocation from "./Pages/UpdateLocation/UpdateLocation";
import Owners from "./Pages/Owners/Owners";
import UpdateOwner from "./Pages/UpdateOwner/UpdateOwner";
import Admins from "./Pages/Admins/Admins";
import CreateAdmin from "./Pages/CreateAdmin/CreateAdmin";
import UpdateAdmin from "./Pages/UpdateAdmin/UpdateAdmin";
import {AccountLogged, AccountUnlogged} from "./Libraries/RouterCondition/AccountCondition";
import {ApiManager} from "./Libraries/Utils/ApiManager";
import Tickets from "./Pages/Tickets/Tickets";
import {UserAccountCache} from "./Libraries/Caches/User/UserAccountCache";
import {LogOutUseCase} from "./Libraries/UseCases/User/LogOutUseCase";
import CreateOwner from "./Pages/CreateOwner/CreateOwner";
import UpdateLocationSchedule from "./Pages/UpdateLocationSchedule/UpdateLocationSchedule";
import Reset from "./Pages/Reset/Reset";
import AccountSettings from "./Pages/AccountSettings/AccountSettings";
import UseQuery from "./Libraries/Hooks/Query/UseQuery";

function Router() {
    const {saveUser} = UserAccountCache();
    const logOutUseCase = LogOutUseCase();
    ApiManager.logInFunction = saveUser;
    ApiManager.logOutFunction = logOutUseCase.execute;

    return <>
        <AccountLogged>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Homepage/>} />
                    <Route path="/translations" element={<Translations/>} />
                    <Route path="/locations" element={<Locations/>}/>
                    <Route path="/locations/create" element={<CreateLocation/>}/>
                    <Route path="/locations/:id" element={<UpdateLocation/>}/>
                    <Route path="/locations/:id/schedule" element={<UpdateLocationSchedule/>}/>
                    <Route path="/owners/" element={<Owners/>}/>
                    <Route path="/owners/create" element={<CreateOwner/>}/>
                    <Route path="/owners/:id" element={<UpdateOwner/>}/>
                    <Route path="/admins/" element={<Admins/>}/>
                    <Route path="/admins/create" element={<CreateAdmin/>}/>
                    <Route path="/admins/:id" element={<UpdateAdmin/>}/>
                    <Route path="/configure-password" element={<ConfigurePassword/>} />
                    <Route path="/locations/:id/tickets" element={<Tickets/>}/>
                    <Route path="/reset" element={<Reset/>}/>
                    <Route path="/account/settings" element={<AccountSettings/>}/>
                </Routes>
            </BrowserRouter>
        </AccountLogged>
        <AccountUnlogged>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Login/>} />
                    <Route path="/configure-password" element={<ConfigurePassword/>} />
                    <Route path="/translations" element={<Navigate to={'/'}/>} />
                    <Route path="/locations" element={<Navigate to={'/'}/>} />
                    <Route path="/locations/create" element={<Navigate to={'/'}/>}/>
                    <Route path="/locations/:id" element={<Navigate to={'/'}/>}/>
                    <Route path="/owners/" element={<Navigate to={'/'}/>}/>
                    <Route path="/owners/create" element={<Navigate to={'/'}/>}/>
                    <Route path="/owners/:id" element={<Navigate to={'/'}/>}/>
                    <Route path="/reset" element={<Reset/>}/>
                </Routes>
            </BrowserRouter>
        </AccountUnlogged>
    </>;
}

export default Router;