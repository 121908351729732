import {useEffect, useState} from "react";
import {ApiManager} from "../../Libraries/Utils/ApiManager";
import PageTemplate from "../../Layout/PageTemplate/PageTemplate";
import Translator from "../../Libraries/Utils/Translator";
import {TRANSLATION_URL, TRANSLATIONS_SAVE_URL} from "../../Libraries/Constants/ApiUrls";
import {
    CTA_SAVE_TRANSLATION, LABEL_NO_TRANSLATION,
    LABEL_SELECT_TRANSLATION_KEY,
    LABEL_TRANSLATION_KEY
} from "../../Libraries/Constants/Labels";
import LabelNewLine from "../../Libraries/Components/Common/Label/LabelNewLine";
import Button from "../../Libraries/Components/Common/Button/Button";
import {getTextCenter} from "../../Libraries/Helpers/Text";
import {
    backgroundColor,
    border,
    classes,
    cursor,
    flex,
    height, overflow,
    padding,
    round,
    width
} from "../../Libraries/Theme/Theme";

function Translations() {
    const localeList = ['en', 'fr'];

    const [data, setData] = useState<any>({
        translations: {},
        isLoading: false,
        selectedTranslationKey: '',
        selectedLocale: localeList[0],
        updatingTranslation: {},
        error: '',
        saveSuccess: false,
    });

    const [debounceSearchKeyword, setDebounceSearchKeyword] = useState();
    const [searchKeyword, setSearchKeyword] = useState('');


    useEffect(() => {
        fetchTranslations();
        setWindowHeight();
    }, [searchKeyword]);

    const fetchTranslations = () => {
        setData({
            ...data,
            isLoading: true
        });
        (async function() {
            try {
                const response = await ApiManager.get(`${TRANSLATION_URL}${searchKeyword !== '' ? `?keyword=${searchKeyword}` : ''}`);

                let newData = {
                    ...data,
                    translations: {...response.data},
                    isLoading: false,
                    updatingTranslation: {}
                };
                if (data.updatingTranslation.newTranslationKey !== undefined && response.data[data.updatingTranslation.newTranslationKey] !== undefined) {
                    newData = {
                        ...newData,
                        ...createUpdatingTranslation(data.updatingTranslation.newTranslationKey, data.locale, response.data)
                    };
                }

                setData(() => { return {...newData}; });
            }catch (e) {
                setData({
                    ...data,
                    isLoading: false
                });
                console.error(`Error while fetching translations ${searchKeyword !== '' ? `with keyword ${searchKeyword}` : ''}`, e);
            }
        })();
    }

    useEffect(() => {
        if (undefined !== debounceSearchKeyword) {
            const timer = setTimeout(() => setSearchKeyword(debounceSearchKeyword), 200);
            return () => clearTimeout(timer);
        }
    }, [debounceSearchKeyword]);

    const saveUpdatingTranslation = () => {
        if (undefined === data.updatingTranslation.newTranslationKey || undefined === data.updatingTranslation.newMessage) {
            return;
        }

        (async function () {
            try {
                const response = await ApiManager.post(TRANSLATIONS_SAVE_URL, {...data.updatingTranslation, locale: data.selectedLocale});

                setData(() => { return {
                    ...data,
                    translations: {...response.data},
                    isLoading: false,
                    saveSuccess: true,
                } });

                fetchTranslations();
            } catch (e) {
                setData({
                    ...data,
                    saveError: e,
                    saveSuccess: false
                });
                console.error(`Error while saving translation ${data.updatingTranslation.newTranslationKey}`, e);
            }
        })();
    };

    const createUpdatingTranslation = (translationKey: string, customLocale?: string, customTranslations?: any) => {
        const translationsToUse = customTranslations ?? data.translations;
        const currentMessage = translationsToUse[translationKey] !== undefined && translationsToUse[translationKey][customLocale ?? data.selectedLocale] !== undefined ? translationsToUse[translationKey][customLocale ?? data.selectedLocale].message : '';

        return {
            selectedTranslationKey: translationKey ?? '',
            updatingTranslation: {
                translationKey: translationKey ?? '',
                newTranslationKey: translationKey ?? '',
                message: currentMessage ?? '',
                newMessage: currentMessage ?? ''
            },
            saveSuccess: false,
        };
    };

    const createNewTranslation = () => {
        setData(() => {
            return {
                ...data,
                ...createUpdatingTranslation('')
            }
        });
    };

    function setWindowHeight(){
        const block = document.getElementById('toResize');
        if (block !== null) {
            block.style.height = (window.innerHeight-145)+'px';
        }
    }
    window.addEventListener("resize",setWindowHeight,false);

    return <PageTemplate>
        <div className={classes(flex("top", "center"))}>
            <div className={classes(padding('xy', '10px'), width('1280px', "min"))}>
                <div className={classes(flex('stretch'), backgroundColor("secondary"), round(), border(), height('100vh-100px', 'max'), height('100vh-100px', 'min'))}>
                    <div className={classes(width('300px'), border('r'))}>
                        <div className={classes(border('b'), flex("center", "left"))}>
                            <div>
                                {/*@ts-ignore*/}
                                <input placeholder={`Search...`} className={classes(backgroundColor("primary"), width('100%'), padding('xy', '10px'))} type={'text'} value={debounceSearchKeyword ?? ''} onChange={(e) => setDebounceSearchKeyword(() => e.target.value)} />
                            </div>
                            <div onClick={createNewTranslation} className={classes(height('40px'), flex("center", "center"), padding('x', '10px'), cursor("pointer"))}>
                                Add
                            </div>
                        </div>
                        <div className={'overflow-y-auto'} id={'toResize'}>
                            {Object.keys(data.translations).map((translationKey, index) => <div key={`translation-${index}`} onClick={() => setData(() => { return {...data, ...createUpdatingTranslation(translationKey)} })} className={`text-left p-[10px] border-b overflow-hidden text-ellipsis cursor-pointer`}>
                                <div className={`${data.selectedTranslationKey === translationKey ? `text-black font-bold` : ``}`}>{translationKey}</div>
                                {data.translations[translationKey] !== undefined && data.translations[translationKey][data.selectedLocale] !== undefined ? <div className={`px-[10px] pt-[10px] italic`}>
                                    {data.translations[translationKey][data.selectedLocale].message}
                                </div> : null}
                            </div>)}
                        </div>
                    </div>
                    <div className={classes(width('100%'), width('100%-300px', '', 'sm'))}>
                        <div className={`flex justify-between items-center border-b`}>
                            <div>
                                {data.updatingTranslation.newTranslationKey === undefined && data.selectedTranslationKey === '' ? null : <input type={'text'} className={classes(padding('xy', '10px'), backgroundColor())} value={data.updatingTranslation.newTranslationKey ?? ''} onChange={(e) => setData({...data, saveSuccess: false, updatingTranslation: {...data.updatingTranslation, newTranslationKey: e.target.value}})} placeholder={Translator.translate(LABEL_TRANSLATION_KEY)}/>}
                            </div>
                            <div className={`p-[4px] flex flex-wrap`}>
                                {localeList.map((locale, index) => <div key={`locale-${index}`} onClick={() => setData(() => { return {...data, ...createUpdatingTranslation(data.selectedTranslationKey, locale), selectedLocale: locale} })} className={`mx-[5px] py-[5px] px-[10px] text-center font-bold rounded-2xl border cursor-pointer ${data.selectedLocale === locale ? `bg-black text-white` : ``}`}>
                                    {locale.toUpperCase()}
                                </div>)}
                            </div>
                        </div>
                        <div className={`h-[calc(100%-80px)] w-full`}>
                            {data.updatingTranslation.newMessage === undefined && data.selectedTranslationKey === '' ? <LabelNewLine padding={'p-[10px]'} italic={true} label={LABEL_SELECT_TRANSLATION_KEY}/> : <textarea
                                className={classes(height("full"), padding('xy', '10px'), backgroundColor("secondary"), width('100%'))}
                                placeholder={Translator.translate(LABEL_NO_TRANSLATION)}
                                value={data.updatingTranslation.newMessage ?? ''}
                                onChange={(e) => setData({...data, saveSuccess: false, updatingTranslation: {...data.updatingTranslation, newMessage: e.target.value}})}
                            />}
                        </div>
                        <div className={getTextCenter('xy')}>
                            <Button size={"large"} label={CTA_SAVE_TRANSLATION} onClick={saveUpdatingTranslation} selected={(data.updatingTranslation.translationKey !== undefined && data.updatingTranslation.translationKey !== data.updatingTranslation.newTranslationKey) || (data.updatingTranslation.translationKey !== undefined && data.updatingTranslation.message !== data.updatingTranslation.newMessage)}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </PageTemplate>;
}

export default Translations;