import MenuLink from "../MenuLink";
import {State} from "../../../../../../Layout/Menu/State";
import {CTA_TRANSLATIONS} from "../../../../../Constants/Labels";

function MenuLinkTranslations() {
    const {toggle} = State();

    return <MenuLink
        link={'/translations'}
        label={CTA_TRANSLATIONS}
        onClick={toggle}
    />
}

export default MenuLinkTranslations;