import {useBetween} from "use-between";
import {useEffect, useState} from "react";
import AbstractIndexedLoader, {IndexedLoaderObjectInterface} from "./AbstractIndexedLoader";
import AdminApi from "../../Calls/AdminApi";

const cache = new AbstractIndexedLoader();

function AdminsSearches() {
    const [adminsSearches, setAdminsSearches] = useState<IndexedLoaderObjectInterface>({});
    const [isLoading, setIsLoading] = useState<boolean|undefined>(false);

    const apiCall = (keyword: string) => {
        return AdminApi.fetchAdmins(keyword);
    };

    const fetch = (keyword: string) => {
        cache.load([keyword], apiCall);
        if (!isLoading) {
            setIsLoading(() => undefined);
        }
    };

    useEffect(() => {
        if (isLoading === undefined) {
            setIsLoading(() => true);
            if (cache.hasLoading()) {
                setTimeout(() => {
                    setIsLoading(() => undefined);
                }, 10);
            } else {
                setAdminsSearches(() => ({...cache.get()}));
                setIsLoading(false);
            }
        }
    }, [isLoading]);

    const getAdminsSearchByKeyword = (keyword: string) => {
        return adminsSearches[keyword]?.data;
    }

    return {fetch, adminsSearches, isLoading, getAdminsSearchByKeyword};
}
export const AdminsSearchesLoader = () => useBetween(AdminsSearches);