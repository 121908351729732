import {AdminsLoader} from "../../../../Api/Loaders/IndexedLoaders/AdminsLoader";
import {useEffect} from "react";
import ResetPasswordTile from "../Tile/ResetPasswordTile";
import ToAdmin from "../Common/Link/ToAdmin";
import Label from "../../../Common/Label/Label";
import Login from "../Common/Data/Login";
import {backgroundColor, border, borderColor, classes, flex, padding, round, width} from "../../../../Theme/Theme";
import LabelNewLine from "../../../Common/Label/LabelNewLine";

interface AdminCardInterface {
    adminId: string;
}

function Card({adminId}: AdminCardInterface) {
    const {fetch: fetchAdmins, getAdminById} = AdminsLoader();

    useEffect(() => {
        fetchAdmins([adminId]);
    }, []);

    const admin = getAdminById(adminId);
    if (undefined === admin) {
        return null;
    }

    return <div className={classes(border(), backgroundColor("secondary"), borderColor("secondary"), width('300px', 'max', 'sm'), width('100%'), round())}>
        <ToAdmin adminId={admin.id}>
            <div className={classes(padding('xy', '10px'))}>
                <LabelNewLine label={<Login adminId={adminId}/>} padding={`p-[5px]`} boldText={true}/>
            </div>
            <div className={classes(flex('top', 'left', '10px'), padding('xy', '10px'), border('t', '1px'))}>
                <ResetPasswordTile adminId={adminId}/>
            </div>
        </ToAdmin>
    </div>;
}

export default Card;