import {AdminsLoader} from "../../../../../Api/Loaders/IndexedLoaders/AdminsLoader";
import {useEffect} from "react";

interface LoginInterface {
    adminId: string;
}

function Login({adminId}: LoginInterface) {
    const {fetch: fetchAdmins, getAdminById} = AdminsLoader();

    useEffect(() => {
        fetchAdmins([adminId]);
    }, []);

    const admin = getAdminById(adminId);

    if (admin === undefined) {
        return null;
    }

    return admin.login;
}

export default Login;