import {useBetween} from "use-between";
import {SearchOwnersUseCase} from "./SearchOwnersUseCase";

function SearchOwnersReset() {
    const {setKeywordDebounce} = SearchOwnersUseCase();

    const execute = () => {
        setKeywordDebounce(() => '');
    }

    return {execute};
}

export const SearchOwnersResetUseCase = () => useBetween(SearchOwnersReset);