import Translator from "../../../../../../Utils/Translator";
import Button from "../../../../../Common/Button/Button";
import {CTA_SUBMIT} from "../../../../../../Constants/Labels";

function ButtonSubmit() {
    return <Button
        type={'submit'}
        label={CTA_SUBMIT}
    />;
}

export default ButtonSubmit;