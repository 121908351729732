import PageTemplate from "../../Layout/PageTemplate/PageTemplate";
import UpdateForm from "../../Libraries/Components/Domain/Admin/Form/UpdateForm";
import {UserAccountCache} from "../../Libraries/Caches/User/UserAccountCache";

function AccountSettings() {
    const accountCache = UserAccountCache();

    if (undefined === accountCache.user || undefined === accountCache.user.id) {
        return null;
    }

    return <PageTemplate>
        <UpdateForm adminId={accountCache.user.id}/>
    </PageTemplate>;
}

export default AccountSettings;