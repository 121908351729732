import {ReactComponent as CircledCross} from "../../Libraries/Icons/CircledCross.svg";
import Translator from "../../Libraries/Utils/Translator";
import PageTemplate from "../../Layout/PageTemplate/PageTemplate";
import {Link} from "react-router-dom";
import {AdminsSearchesLoader} from "../../Libraries/Api/Loaders/IndexedLoaders/AdminsSearchesLoader";
import {SearchAdminsUseCase} from "../../Libraries/UseCases/Admin/SearchAdminsUseCase";
import Button from "../../Libraries/Components/Common/Button/Button";
import {AutoSearchAdminsUseCase} from "../../Libraries/UseCases/Admin/AutoSearchAdminsUseCase";
import Label from "../../Libraries/Components/Common/Label/Label";
import {CTA_CREATE_ADMIN, LABEL_ADMIN_SEARCH_BY_KEYWORD, LABEL_FILTER_ZONE} from "../../Libraries/Constants/Labels";
import {
    backgroundColor,
    border, borderColor,
    classes,
    flex,
    margin,
    padding,
    round,
    textColor,
    textSize, width
} from "../../Libraries/Theme/Theme";
import Card from "../../Libraries/Components/Domain/Admin/Card/Card";

function Admins() {
    AutoSearchAdminsUseCase();
    const {getAdminsSearchByKeyword} = AdminsSearchesLoader();
    const {keywordDebounce, keyword} = SearchAdminsUseCase();

    const adminsByKeyword = keyword === undefined ? [] : getAdminsSearchByKeyword(keyword) ?? [];

    const {setKeywordDebounce} = SearchAdminsUseCase();

    return <PageTemplate>
        <div className={classes(width('100%'))}>
            <div className={classes(border(), borderColor("primary"), backgroundColor("secondary"), textColor(), padding('xy', '10px'), round())}>
                <Label label={LABEL_FILTER_ZONE} className={classes(textSize('20px'))}/>
                <div className={classes(flex("center", "between", '10px'))}>
                    <div>
                        <div className={classes(border(), borderColor('primary'), flex("center"), round(), backgroundColor("primary"), padding('xy', '5px'), margin('t', '10px'), border())}>
                            <div>
                                <input className={classes(padding('xy', '5px'), backgroundColor('primary'), textColor('primary'))} type={'text'} onChange={(e: any) => setKeywordDebounce(() => e.target.value)} value={keywordDebounce ?? ''} placeholder={Translator.translate(LABEL_ADMIN_SEARCH_BY_KEYWORD)}/>
                            </div>
                            <div>
                                <CircledCross className={`cursor-pointer`} width={`20px`} onClick={() => setKeywordDebounce('')}/>
                            </div>
                        </div>
                    </div>
                    <div>
                        <Link to={`/admins/create`}><Button className={classes(padding('xy', '10px', '', true))} label={CTA_CREATE_ADMIN}/></Link>
                    </div>
                </div>
            </div>
            <div className={classes(flex("top", "center", '10px'), margin('t', '10px'))}>
                {adminsByKeyword.map((adminId: string, index: number) => <Card key={`location-${index}`} adminId={adminId}/>)}
            </div>
        </div>
    </PageTemplate>;
}

export default Admins;