import {useEffect} from "react";
import Label from "../../../Common/Label/Label";
import Translator from "../../../../Utils/Translator";
import {LABEL_RESET_PASSWORD} from "../../../../Constants/Labels";
import {OwnersLoader} from "../../../../Api/Loaders/IndexedLoaders/OwnersLoader";
import {backgroundColor, border, classes, padding, round} from "../../../../Theme/Theme";

interface ResetPasswordTileInterface {
    ownerId: string;
}

function ResetPasswordTile({ownerId}: ResetPasswordTileInterface) {
    const {fetch: fetchOwners, getOwnerById} = OwnersLoader();

    useEffect(() => {
        fetchOwners([ownerId]);
    }, []);

    const owner = getOwnerById(ownerId);

    if (owner === undefined || owner.requireConfiguration === undefined) {
        return null;
    }

    return <div className={classes(round("full"), padding('xy', '5px'), border(), backgroundColor("primary"))}>
        <Label label={Translator.translate(LABEL_RESET_PASSWORD)}/>
    </div>;
}

export default ResetPasswordTile;