import PageTemplate from "../../Layout/PageTemplate/PageTemplate";
import {GetUserDataUseCase} from "../../Libraries/UseCases/User/GetUserDataUseCase";
import LabelNewLine from "../../Libraries/Components/Common/Label/LabelNewLine";
import {LABEL_WELCOME_1, LABEL_WELCOME_2} from "../../Libraries/Constants/Labels";
import {
    border,
    borderColor,
    classes,
    flex,
    height,
    margin,
    padding, textAlign,
    textSize
} from "../../Libraries/Theme/Theme";

function Homepage() {
    const getUserDataUseCase = GetUserDataUseCase();
    const userData = getUserDataUseCase.execute();

    return <PageTemplate centeredContent={true}>
        <div className={classes(flex("center", "center"), height("full"))}>
            <div className={classes(border('r', '1px'), borderColor("primary"), padding('r', '5px'), margin('r', '5px'))}>
                <LabelNewLine className={classes(textSize('20px'), textAlign("right"))} label={LABEL_WELCOME_1}/>
                <LabelNewLine className={classes(textSize('20px'), textAlign('right'))} label={LABEL_WELCOME_2}/>
            </div>
            <LabelNewLine className={classes(textSize('50px'))} label={userData === undefined ? null : userData.login}/>
        </div>
    </PageTemplate>;
}

export default Homepage;