import Translator from "../../../../Utils/Translator";
import {AdminsLoader} from "../../../../Api/Loaders/IndexedLoaders/AdminsLoader";
import {useEffect} from "react";
import ResetPassword from "../Todo/ResetPassword";
import {LABEL_TODO_LIST_TITLE} from "../../../../Constants/Labels";
import {backgroundColor, border, classes, margin, padding, round, textColor, textSize} from "../../../../Theme/Theme";
import LabelNewLine from "../../../Common/Label/LabelNewLine";

interface TodoListInterface {
    adminId: string;
}

function TodoList({adminId}: TodoListInterface) {
    const {fetch: fetchAdmins, getAdminById} = AdminsLoader();

    useEffect(() => {
        fetchAdmins([adminId]);
    }, []);

    const admin = getAdminById(adminId);
    if (undefined === admin) {
        return null;
    }

    return <div className={classes(round(), backgroundColor("secondary"), textColor("secondary"), margin('y', '10px'), padding('xy', '10px'), border())}>
        <LabelNewLine className={classes(textSize('20px'))} label={LABEL_TODO_LIST_TITLE}/>
        <ResetPassword adminId={adminId}/>
    </div>;
}

export default TodoList;