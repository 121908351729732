import {useEffect} from "react";
import {OwnersLoader} from "../../../../Api/Loaders/IndexedLoaders/OwnersLoader";
import Label from "../../../Common/Label/Label";
import Login from "../Common/Data/Login";
import ResetPasswordTile from "../Tile/ResetPasswordTile";
import ToOwner from "../Common/Redirection/ToOwner";
import {
    backgroundColor,
    boldText,
    border,
    borderColor,
    classes,
    flex,
    padding,
    round,
    width
} from "../../../../Theme/Theme";
import LabelNewLine from "../../../Common/Label/LabelNewLine";

interface OwnerCardInterface {
    ownerId: string;
}

function Card({ownerId}: OwnerCardInterface) {
    const {fetch: fetchOwners, getOwnerById} = OwnersLoader();

    useEffect(() => {
        fetchOwners([ownerId]);
    }, []);

    const owner = getOwnerById(ownerId);
    if (undefined === owner) {
        return null;
    }

    return <div className={classes(border(), backgroundColor("secondary"), borderColor("secondary"), width('300px', 'max', 'sm'), width('100%'), round())}>
        <ToOwner ownerId={ownerId}>
            <div className={classes(padding('xy', '10px'))}>
                <LabelNewLine className={classes(boldText())} label={<Login ownerId={ownerId}/>}/>
            </div>
            <div className={classes(flex('top', 'left', '10px'), padding('xy', '10px'), border('t', '1px'))}>
                <ResetPasswordTile ownerId={ownerId}/>
            </div>
        </ToOwner>
    </div>
}

export default Card;