import InputTemporaryPassword from "../Common/Form/InputPassword/InputTemporaryPassword";
import ButtonSubmit from "../Common/Form/Button/ButtonSubmit";
import InputEmail from "../Common/Form/Input/InputEmail";
import InputPassword from "../Common/Form/InputPassword/InputPassword";
import {ConfigurePasswordUseCase} from "../../../../UseCases/User/ConfigurePasswordUseCase";
import Label from "../../../Common/Label/Label";
import {LABEL_CONFIGURE_PASSWORD, LABEL_ERROR, LABEL_SUCCESS} from "../../../../Constants/Labels";
import LabelNewLine from "../../../Common/Label/LabelNewLine";
import {
    backgroundColor,
    boldText,
    border,
    classes,
    display, flex,
    margin,
    padding,
    round,
    textSize
} from "../../../../Theme/Theme";

function ConfigurePasswordForm() {
    const {form, handleChange, handleBlur, handleFocus} = ConfigurePasswordUseCase();
    const configurePasswordUseCase = ConfigurePasswordUseCase();

    return <div className={classes(border(), backgroundColor("secondary"), padding('xy', '10px'), round())}>
        <LabelNewLine className={classes(textSize('20px'), boldText())} label={LABEL_CONFIGURE_PASSWORD}/>
        <form onSubmit={configurePasswordUseCase.execute} className={classes(margin('t', '10px'))}>
            <div>
                <InputEmail className={classes(border(), backgroundColor("primary"))} form={form} handleBlur={handleBlur} handleChange={handleChange} handleFocus={handleFocus}/>
            </div>
            <div>
                <InputPassword className={classes(border(), backgroundColor("primary"), margin('t', '10px'))} form={form} handleBlur={handleBlur} handleChange={handleChange} handleFocus={handleFocus}/>
            </div>
            <div>
                <InputTemporaryPassword className={classes(display('none'), border(), backgroundColor("primary"), margin('t', '10px'))} form={form} handleChange={handleChange}/>
            </div>
            <div className={classes(margin('t', '10px'))}>
                {form.success !== '' && <LabelNewLine className={classes(margin('t', '10px'))} label={typeof(form.success) === 'object' ? form.success : LABEL_SUCCESS(form.success)} textColor={"success"}/>}
                {form.error !== '' && <LabelNewLine className={classes(margin('t', '10px'))} label={typeof(form.error) === 'object' ? form.error : LABEL_ERROR(form.error)} textColor={"error"}/>}
            </div>
            <div className={classes(flex("center", 'right'), margin('t', '10px'))}>
                <ButtonSubmit/>
            </div>
        </form>
    </div>;
}

export default ConfigurePasswordForm;