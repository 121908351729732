import MenuLink from "../MenuLink";
import {State} from "../../../../../../Layout/Menu/State";
import Translator from "../../../../../Utils/Translator";
import {CTA_OWNERS} from "../../../../../Constants/Labels";

const TRANSLATION_PREFIX = 'libraries__components_menu_menu_link_links_menu_link_owners__';

function MenuLinkOwners() {
    const {toggle} = State();

    return <MenuLink
        link={'/owners'}
        label={CTA_OWNERS}
        onClick={toggle}
    />
}

export default MenuLinkOwners;