import {useBetween} from "use-between";
import {useEffect, useState} from "react";
import {OwnersSearchesLoader} from "../../Api/Loaders/IndexedLoaders/OwnersSearchesLoader";

function SearchAdmins() {
    const {fetch: fetchOwnersSearches} = OwnersSearchesLoader();

    const [keyword, setKeyword] = useState<undefined|string>();
    const [keywordDebounce, setKeywordDebounce] = useState<undefined|string>();

    useEffect(() => {
        if (undefined !== keywordDebounce) {
            const timer = setTimeout(() => setKeyword(keywordDebounce), 500);
            return () => clearTimeout(timer);
        }
    }, [keywordDebounce]);

    useEffect(() => {
        if (keyword !== undefined) {
            fetchOwnersSearches(keyword);
        }
    }, [keyword]);

    const execute = (e: any) => setKeywordDebounce(e.target.value);

    return {execute, keywordDebounce, keyword, setKeywordDebounce};
}

export const SearchOwnersUseCase = () => useBetween(SearchAdmins);