import Input from "../../../../../Common/Form/Input";
import Translator from "../../../../../../Utils/Translator";
import {LABEL_EMAIL, LABEL_FIRSTNAME, LABEL_LOGIN} from "../../../../../../Constants/Labels";

interface InputFirstnameInterface {
    form: any;
    handleChange: any;
    handleBlur: any;
    handleFocus: any;
    className?: string;
}

function InputFirstname({form, handleChange, handleBlur, handleFocus, className}: InputFirstnameInterface) {
    return <Input
        inputData={form.fields.firstname}
        name={'firstname'}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        displayBorders={true}
        placeholder={Translator.translate(LABEL_FIRSTNAME)}
        size={"full"}
        className={className ?? ''}
    />;
}

export default InputFirstname;