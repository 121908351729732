import {useBetween} from "use-between";
import {useEffect, useState} from "react";
import {LocationsSearchesLoader} from "../../Api/Loaders/IndexedLoaders/LocationsSearchesLoader";
import {AdminsSearchesLoader} from "../../Api/Loaders/IndexedLoaders/AdminsSearchesLoader";

function SearchAdmins() {
    const {fetch: fetchAdminsSearches} = AdminsSearchesLoader();

    const [keyword, setKeyword] = useState<undefined|string>();
    const [keywordDebounce, setKeywordDebounce] = useState<undefined|string>();

    useEffect(() => {
        if (undefined !== keywordDebounce) {
            const timer = setTimeout(() => setKeyword(keywordDebounce), 500);
            return () => clearTimeout(timer);
        }
    }, [keywordDebounce]);

    useEffect(() => {
        if (keyword !== undefined) {
            fetchAdminsSearches(keyword);
        }
    }, [keyword]);

    const execute = (e: any) => setKeywordDebounce(e.target.value);

    return {execute, keywordDebounce, keyword, setKeywordDebounce};
}

export const SearchAdminsUseCase = () => useBetween(SearchAdmins);