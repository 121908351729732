import MenuLink from "../MenuLink";
import {State} from "../../../../../../Layout/Menu/State";
import {CTA_ADMINS} from "../../../../../Constants/Labels";

function MenuLinkAdmins() {
    const {toggle} = State();

    return <MenuLink
        link={'/admins'}
        label={CTA_ADMINS}
        onClick={toggle}
    />
}

export default MenuLinkAdmins;