import {useParams} from "react-router-dom";
import {useEffect} from "react";
import PageTemplate from "../../Layout/PageTemplate/PageTemplate";
import {LocationsLoader} from "../../Libraries/Api/Loaders/IndexedLoaders/LocationsLoader";
import ScheduleForm from "../../Libraries/Components/Domain/Location/Form/ScheduleForm";
import {LABEL_ERROR, LABEL_SUCCESS} from "../../Libraries/Constants/Labels";
import LabelNewLine from "../../Libraries/Components/Common/Label/LabelNewLine";
import {classes, textSize} from "../../Libraries/Theme/Theme";

function UpdateLocationSchedule() {
    const {id} = useParams();
    const {fetch: fetchLocations, getLocationById} = LocationsLoader();

    useEffect(() => {
        if (id !== undefined) {
            fetchLocations([id]);
        }
    }, []);

    if (id === undefined) {
        return null;
    }

    const location = getLocationById(id);
    if (location === undefined) {
        return null;
    }

    return <PageTemplate>
        <ScheduleForm locationId={id}/>
    </PageTemplate>;
}

export default UpdateLocationSchedule;