import {FormHook} from "../../Hooks/Form/FormHook";
import {useEffect} from "react";
import Translator from "../../Utils/Translator";
import {useBetween} from "use-between";
import OwnerApi from "../../Api/Calls/OwnerApi";
import AbstractApi from "../../Api/Calls/AbstractApi";

const TRANSLATION_PREFIX = 'libraries__use_cases_owner_create_owner_use_case__';

function CreateOwner() {
    const {form, handleChange, handleBlur, handleFocus, shouldSubmit, setForm, setConstrains} = FormHook(['login', 'email']);

    useEffect(() => {
        (async function () {
            const response = await OwnerApi.fetchCreateOwnerFormValidator();
            if (AbstractApi.isSuccess(response)) {
                setConstrains(response.data);
            }
        })();
    }, []);

    const execute = async (e: any) => {
        e.preventDefault();
        if (!shouldSubmit()) {
            return;
        }

        setForm({
            ...form,
            isLoading: true,
            error: '',
            success: '',
        });

        const response = await OwnerApi.createOwner(form);
        if (AbstractApi.isSuccess(response)) {
            document.location.href = `/owners/${response.data.id}`;
        } else {
            setForm({
                ...form,
                isLoading: false,
                error: response.data,
            });
        }
    };

    return {form, handleChange, handleBlur, handleFocus, execute};
}

export const CreateOwnerUseCase = () => useBetween(CreateOwner);