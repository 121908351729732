import {useParams} from "react-router-dom";
import PageTemplate from "../../Layout/PageTemplate/PageTemplate";
import UpdateForm from "../../Libraries/Components/Domain/Admin/Form/UpdateForm";

function UpdateAdmin() {
    const {id} = useParams();

    if (id === undefined) {
        return null;
    }

    return <PageTemplate>
        <UpdateForm adminId={id}/>
    </PageTemplate>;
}

export default UpdateAdmin;