import Input from "../../../../../Common/Form/Input";
import Translator from "../../../../../../Utils/Translator";
import {LABEL_EMAIL, LABEL_FIRSTNAME, LABEL_LASTNAME, LABEL_LOGIN} from "../../../../../../Constants/Labels";

interface InputLastnameInterface {
    form: any;
    handleChange: any;
    handleBlur: any;
    handleFocus: any;
    className?: string;
}

function InputLastname({form, handleChange, handleBlur, handleFocus, className}: InputLastnameInterface) {
    return <Input
        inputData={form.fields.lastname}
        name={'lastname'}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        displayBorders={true}
        placeholder={Translator.translate(LABEL_LASTNAME)}
        size={"full"}
        className={className ?? ''}
    />;
}

export default InputLastname;