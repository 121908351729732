import Copy from "../../../Common/Copy/Copy";
import {AdminsLoader} from "../../../../Api/Loaders/IndexedLoaders/AdminsLoader";
import {useEffect} from "react";
import {CTA_COPIED, CTA_COPY_LINK, LABEL_RESET_PASSWORD} from "../../../../Constants/Labels";
import Translator from "../../../../Utils/Translator";
import {FRONT__OWNER__CONFIGURE_PASSWORD_URL} from "../../../../Constants/FrontUrls";
import Label from "../../../Common/Label/Label";
import {classes, flex} from "../../../../Theme/Theme";

interface ConfigurePasswordInterface {
    adminId: string;
}

function ResetPassword({adminId}: ConfigurePasswordInterface) {
    const {fetch: fetchAdmins, getAdminById} = AdminsLoader();

    useEffect(() => {
        fetchAdmins([adminId]);
    }, []);

    const admin = getAdminById(adminId);

    if (admin === undefined) {
        return null;
    }

    return <div className={classes(flex('center', 'left', '20px'))}>
        {admin.requireConfiguration !== undefined ?
            <NeedToDo adminId={adminId}/>
            : <AlreadyDone/>
        }
    </div>
}

function AlreadyDone() {
    return <Label textColor={'success'} label={LABEL_RESET_PASSWORD}/>;
}

interface NeedToDoInterface {
    adminId: string;
}

function NeedToDo({adminId}: NeedToDoInterface) {
    const {fetch: fetchAdmins, getAdminById} = AdminsLoader();

    useEffect(() => {
        fetchAdmins([adminId]);
    }, []);

    const admin = getAdminById(adminId);
    if (admin === undefined || admin.requireConfiguration === undefined) {
        return null;
    }

    return <>
        <Label textColor={'error'} label={LABEL_RESET_PASSWORD}/>
        <Copy
            stringToCopy={FRONT__OWNER__CONFIGURE_PASSWORD_URL(admin.email, admin.requireConfiguration)}
            copiedLabel={Translator.translate(CTA_COPIED)}
            copyLabel={Translator.translate(CTA_COPY_LINK)}
        />
    </>
}

export default ResetPassword;