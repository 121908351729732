import {ApiManager} from "../../Utils/ApiManager";
import {
    ADMIN_URL, ADMINS_TO_CONFIGURE_URL,
    ADMINS_URL,
    CREATE_ADMIN_FORM_VALIDATOR_URL,
    UPDATE_ADMIN_FORM_VALIDATOR_URL
} from "../../Constants/ApiUrls";
import AbstractApi from "./AbstractApi";

class AdminApi {
    static async fetchCreateAdminFormValidator() {
        try {
            const response = await ApiManager.get(CREATE_ADMIN_FORM_VALIDATOR_URL);

            return AbstractApi.returnSuccessResponse(response);
        } catch (e: any) {
            return AbstractApi.returnErrorResponse(e, 'fetchCreateAdminFormValidator');
        }
    }

    static async fetchUpdateAdminFormValidator() {
        try {
            const response = await ApiManager.get(UPDATE_ADMIN_FORM_VALIDATOR_URL);
            return AbstractApi.returnSuccessResponse(response);
        } catch (e: any) {
            return AbstractApi.returnErrorResponse(e, 'fetchUpdateAdminFormValidator');
        }
    }

    static async createAdmin(form: any) {
        try {
            const response = await ApiManager.post(ADMINS_URL(), {
                // @ts-ignore
                login: form.fields.login.value,
                // @ts-ignore
                email: form.fields.email.value
            });
            return AbstractApi.returnSuccessResponse(response);
        } catch (e: any) {
            return AbstractApi.returnErrorResponse(e, 'createAdmin', {form});
        }
    }

    static async updateAdmin(adminId: string, form: any) {
        try {
            const response = await ApiManager.put(ADMIN_URL(adminId), {
                // @ts-ignore
                login: form.fields.login.value,
                // @ts-ignore
                email: form.fields.email.value,
                // @ts-ignore
                firstname: form.fields.firstname.value,
                // @ts-ignore
                lastname: form.fields.lastname.value,
            });
            return AbstractApi.returnSuccessResponse(response);
        } catch (e: any) {
            return AbstractApi.returnErrorResponse(e, 'updateAdmin', {adminId, form});
        }
    }

    static async fetchAdmins(keyword?: string) {
        try {
            const response = await ApiManager.get(ADMINS_URL(keyword));
            return AbstractApi.returnSuccessResponse(response);
        } catch (e: any) {
            return AbstractApi.returnErrorResponse(e, 'fetchAdmins', {keyword});
        }
    };

    static async fetchAdminsToConfigure() {
        try {
            const response = await ApiManager.get(ADMINS_TO_CONFIGURE_URL);
            return AbstractApi.returnSuccessResponse(response);
        } catch (e: any) {
            return AbstractApi.returnErrorResponse(e, 'fetchAdminsToConfigure');
        }
    };

    static async fetchAdmin(adminId: string) {
        try {
            const response = await ApiManager.get(ADMIN_URL(adminId));
            return AbstractApi.returnSuccessResponse(response);
        } catch (e: any) {
            return AbstractApi.returnErrorResponse(e, 'fetchAdmin', {adminId});
        }
    };
}

export default AdminApi;