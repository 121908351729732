import {AdminsLoader} from "../../../../Api/Loaders/IndexedLoaders/AdminsLoader";
import {useEffect} from "react";
import Label from "../../../Common/Label/Label";
import Translator from "../../../../Utils/Translator";
import {LABEL_RESET_PASSWORD} from "../../../../Constants/Labels";
import {backgroundColor, border, classes, padding, round} from "../../../../Theme/Theme";

interface ResetPasswordTileInterface {
    adminId: string;
}

function ResetPasswordTile({adminId}: ResetPasswordTileInterface) {
    const {fetch: fetchAdmins, getAdminById} = AdminsLoader();

    useEffect(() => {
        fetchAdmins([adminId]);
    }, []);

    const admin = getAdminById(adminId);

    if (admin === undefined || admin.requireConfiguration === undefined) {
        return null;
    }

    return <div className={classes(round("full"), padding('xy', '5px'), border(), backgroundColor("primary"))}>
        <Label label={Translator.translate(LABEL_RESET_PASSWORD)}/>
    </div>;
}

export default ResetPasswordTile;