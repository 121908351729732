import PageTemplate from "../../Layout/PageTemplate/PageTemplate";
import {useEffect, useState} from "react";
import Button from "../../Libraries/Components/Common/Button/Button";
import {CTA_SUBMIT, LABEL_PASSWORD, LABEL_RESET_ALL, LABEL_SUCCESS} from "../../Libraries/Constants/Labels";
import AdminEntity from "../../Libraries/Entities/AdminEntity";
import {Link} from "react-router-dom";
import Label from "../../Libraries/Components/Common/Label/Label";
import AdminApi from "../../Libraries/Api/Calls/AdminApi";
import SystemApi from "../../Libraries/Api/Calls/SystemApi";
import {
    backgroundColor,
    border,
    classes,
    flex,
    margin,
    padding,
    round,
    textAlign,
    width
} from "../../Libraries/Theme/Theme";
import AbstractApi from "../../Libraries/Api/Calls/AbstractApi";
import {FormHook} from "../../Libraries/Hooks/Form/FormHook";
import InputPwd from "../../Libraries/Components/Common/Form/InputPwd";
import {getLabel} from "../../Libraries/Helpers/Label";

function Reset() {
    const [admins, setAdmins] = useState([]);
    const [resetAccess, setResetAccess] = useState<string|undefined>(undefined);
    const [resetStatus, setResetStatus] = useState<undefined|boolean>(undefined);
    const {form, handleChange, shouldSubmit, setForm} = FormHook(['password']);

    useEffect(() => {
        syncAdminsToConfigure();
    }, []);

    const syncAdminsToConfigure = () => {
        (async function () {
            try {
                const response = await AdminApi.fetchAdminsToConfigure();
                setAdmins(response.data);
            } catch (e: any) {}
        })();
    };

    const resetAll = async () => {
        setResetStatus(() => undefined);
        // @ts-ignore
        const response = await SystemApi.resetAll(resetAccess);
        if (AbstractApi.isSuccess(response)) {
            setResetStatus(() =>true);
            syncAdminsToConfigure();
        }
    };

    const submitResetAllAccess = (e: any) => {
        e.preventDefault();
        setResetAccess(() => undefined);
        // @ts-ignore
        if (!shouldSubmit() && form.fields.password.value.length === 0) {
            return;
        }

        // @ts-ignore
        (async function() {
            // @ts-ignore
            const response = await SystemApi.resetAllAccess(form.fields.password.value);
            if (AbstractApi.isSuccess(response)) {
                // @ts-ignore
                setResetAccess(() => form.fields.password.value);
            }

            setForm(() => ({
                ...form,
                isLoading: false,
            }));
        })();
    };

    return <PageTemplate centeredContent={true}>
        <div className={classes(backgroundColor("secondary"), padding('xy', '10px'), round())}>
            {resetAccess === undefined ? <>
                <form onSubmit={submitResetAllAccess}>
                    <InputPwd className={classes(border(), width('100%'), backgroundColor())} placeholder={getLabel(LABEL_PASSWORD)} inputData={form.fields.password} onChange={handleChange} name={'password'}/>
                    <div className={classes(flex("center", 'right'), margin('t', '10px'))}>
                        <Button type={'submit'} label={getLabel(CTA_SUBMIT)}/>
                    </div>
                </form>
            </> : <>
                {resetStatus !== undefined && <div className={classes(margin('b', '10px'), textAlign("center"))}>
                    <Label textColor={'success'} label={LABEL_SUCCESS(`reset_done`)}/>
                </div>}
                <div className={classes(flex("center", "center"))}>
                    <Button onClick={resetAll} label={LABEL_RESET_ALL} className={classes(width('100%'), border())}/>
                </div>
                {admins.length > 0 && <div>
                    {admins.map((admin: AdminEntity) => <div className={classes(padding('y', '10px'), backgroundColor("primary", true), textAlign("center"), padding('xy', '10px'), margin('t', '10px'), round(), border())}><Link to={`/configure-password?email=${admin.email}&temporaryPassword=${admin.requireConfiguration}`} className={`hover:underline`}>
                        {admin.firstname} {admin.lastname}
                    </Link></div>)}
                </div>}
            </>}
        </div>
    </PageTemplate>;
}

export default Reset;