import {CreateAdminUseCase} from "../../../../UseCases/Admin/CreateAdminUseCase";
import InputLogin from "../Common/Form/Input/InputLogin";
import InputEmail from "../Common/Form/Input/InputEmail";
import ButtonSubmit from "../Common/Form/Button/ButtonSubmit";
import Label from "../../../Common/Label/Label";
import Translator from "../../../../Utils/Translator";
import {LABEL_ERROR, LABEL_NEW_ADMIN} from "../../../../Constants/Labels";
import {
    backgroundColor,
    boldText,
    border,
    classes,
    flex,
    margin,
    padding,
    round,
    textSize
} from "../../../../Theme/Theme";
import LabelNewLine from "../../../Common/Label/LabelNewLine";

function CreateForm() {
    const {form, handleChange, handleBlur, handleFocus} = CreateAdminUseCase();
    const createAdminUseCase = CreateAdminUseCase();

    return <div className={classes(border(),backgroundColor("secondary"), padding('xy', '10px'), round())}>
        <LabelNewLine className={classes(textSize('20px'), boldText())} label={Translator.translate(LABEL_NEW_ADMIN)}/>
        <form onSubmit={createAdminUseCase.execute} className={classes(margin('t', '10px'))}>
            <div>
                <div>
                    <InputLogin className={classes(border(), backgroundColor('primary'))} form={form} handleChange={handleChange} handleBlur={handleBlur} handleFocus={handleFocus}/>
                </div>
                <div>
                    <InputEmail className={classes(border(), margin('t', '10px'), backgroundColor('primary'))} form={form} handleChange={handleChange} handleBlur={handleBlur} handleFocus={handleFocus}/>
                </div>
            </div>
            <div className={classes(margin('t', '10px'))}>
                {form.error !== '' && <LabelNewLine className={classes(margin('t', '10px'))} label={typeof(form.error) === 'object' ? form.error : LABEL_ERROR(form.error)} textColor={"error"}/>}
            </div>
            <div className={classes(flex("center", 'right'), margin('t', '10px'))}>
                <ButtonSubmit/>
            </div>
        </form>
    </div>;
}

export default CreateForm;