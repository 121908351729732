import {useBetween} from "use-between";
import {useEffect, useState} from "react";
import AbstractIndexedLoader, {IndexedLoaderObjectInterface} from "./AbstractIndexedLoader";
import OwnerApi from "../../Calls/OwnerApi";

const cache = new AbstractIndexedLoader();

function OwnersSearches() {
    const [ownersSearches, setOwnersSearches] = useState<IndexedLoaderObjectInterface>({});
    const [isLoading, setIsLoading] = useState<boolean|undefined>(false);

    const apiCall = (keyword: string) => {
        return OwnerApi.fetchOwners(keyword);
    };

    const fetch = (keyword: string) => {
        cache.load([keyword], apiCall);
        if (!isLoading) {
            setIsLoading(() => undefined);
        }
    };

    useEffect(() => {
        if (isLoading === undefined) {
            setIsLoading(() => true);
            if (cache.hasLoading()) {
                setTimeout(() => {
                    setIsLoading(() => undefined);
                }, 10);
            } else {
                setOwnersSearches(() => ({...cache.get()}));
                setIsLoading(false);
            }
        }
    }, [isLoading]);

    const getOwnersSearchByKeyword = (keyword: string) => {
        return ownersSearches[keyword]?.data;
    }

    return {fetch, ownersSearches, isLoading, getOwnersSearchByKeyword};
}
export const OwnersSearchesLoader = () => useBetween(OwnersSearches);