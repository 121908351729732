import {FormHook} from "../../Hooks/Form/FormHook";
import {useEffect} from "react";
import {useBetween} from "use-between";
import AdminApi from "../../Api/Calls/AdminApi";
import AbstractApi from "../../Api/Calls/AbstractApi";

function CreateAdmin() {
    const {form, handleChange, handleBlur, handleFocus, shouldSubmit, setForm, setConstrains} = FormHook(['login', 'email']);

    useEffect(() => {
        (async function () {
                const response = await AdminApi.fetchCreateAdminFormValidator();
                if (AbstractApi.isSuccess(response)) {
                    setConstrains(response.data);
                }
        })();
    }, []);

    const execute = async (e: any) => {
        e.preventDefault();
        if (!shouldSubmit()) {
            return;
        }

        setForm({
            ...form,
            isLoading: true,
            error: '',
            success: '',
        });

        const response = await AdminApi.createAdmin(form);
        if (AbstractApi.isSuccess(response)) {
            document.location.href = `/admins/${response.data.id}`;
        } else {
            setForm({
                ...form,
                isLoading: false,
                error: response.data,
            });
        }
    };

    return {form, handleChange, handleBlur, handleFocus, execute};
}

export const CreateAdminUseCase = () => useBetween(CreateAdmin);