import {ReactElement} from "react";
import {FRONT__COMMON__OWNER_URL} from "../../../../../Constants/FrontUrls";
import Link from "../../../../Common/Link/Link";

interface ToOwnerInterface {
    ownerId: string;
    children: ReactElement|ReactElement[]|string;
}

function ToOwner({ownerId, children}: ToOwnerInterface) {
    return <Link to={FRONT__COMMON__OWNER_URL(ownerId)}>
        {children}
    </Link>;
}

export default ToOwner;