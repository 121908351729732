import {LogInUseCase} from "../../../../UseCases/User/LogInUseCase";
import InputLoginOrEmail from "../Common/Form/Input/InputLoginOrEmail";
import InputPassword from "../Common/Form/InputPassword/InputPassword";
import ButtonSubmit from "../Common/Form/Button/ButtonSubmit";
import Label from "../../../Common/Label/Label";
import {LABEL_ERROR, LABEL_LOG_IN} from "../../../../Constants/Labels";
import LabelNewLine from "../../../Common/Label/LabelNewLine";
import {backgroundColor, boldText, border, classes, margin, padding, round, textSize} from "../../../../Theme/Theme";

function LoginForm() {
    const {form, handleChange} = LogInUseCase();
    const logInUseCase = LogInUseCase();

    return <div className={classes(border(),backgroundColor("secondary"), padding('xy', '10px'), round())}>
        <LabelNewLine className={classes(textSize('20px'), boldText())} label={LABEL_LOG_IN}/>
        <form onSubmit={logInUseCase.execute} className={classes(margin('t', '10px'))}>
            <div>
                <InputLoginOrEmail className={classes(backgroundColor('primary'), border())} form={form} handleChange={handleChange}/>
            </div>
            <div>
                <InputPassword className={classes(margin('t', '10px'), border(), backgroundColor('primary'))} form={form} handleChange={handleChange}/>
            </div>
            <div className={`flex justify-between pt-[10px] items-center`}>
                <div>
                    {form.error.length > 0 && <Label textColor={'error'} label={LABEL_ERROR(form.error)}/>}
                </div>
                <div>
                    <ButtonSubmit/>
                </div>
            </div>
        </form>
    </div>;
}

export default LoginForm;